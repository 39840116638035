import React, { useState, useEffect } from 'react';
// import TextDescription from './TextDescription';

export const StateContext = React.createContext();

// const {Provider, Consumer} = React.createContext()



const StateContextProvider = (props) => {

    const [ subtitleQue, setSubtitleQue ] = useState([]);
    // const [ subtitles, setsubtitles ] = useState([]);
    const [ letterPositions, setLetterPositions ] = useState([]);
    const [ recPos, setRecPos ] = useState(false);
    const [ shareId, setShareId ] = useState();
    const [ hasChanged, setHasChanged ] = useState(false)
    const [ preComp, setPreComp ] = useState()
    const [ globalZ, setGlobalZ ] = useState(1)

    // console.log(letterPositions);

    useEffect(() => {
        console.log("CONTEXT RENDER")
        // setLetterPositions([ 56 ]);
    }, []);
    useEffect(() => {
        // setLetterPositions({})
        if (recPos) {
            setTimeout(() => {
                setRecPos(false)
                saveLetterPositions(letterPositions, shareId)
                setLetterPositions([])
            }, 500)
        }
    }, [ recPos ]);

    useEffect(() => {
        if(shareId) {
            setRecPos(true)
            console.log(shareId);

        }
    }, [ shareId ])

    // function startSavingPos(id) {

    // }

    // useEffect(() => {

    //     console.log(subtitleQue)
    //     let subs = []
    //     // subtitleQue.forEach((sub, i) => {
    //     //     if (sub) { subs.unshift(<TextDescription key={i} index={i} que={subtitleQue} sub={sub}/>) }
    //     //     // if (sub) { return (<p key={i} className={`no${sub.id}`}>{`[ ${sub.text} ${sub.playState} ]`}</p>) }
    //     // })
    //     // let subs = subtitleQue.map((sub, i) => {
    //     //     if (sub) { return (<TextDescription key={i} index={i} sub={sub}/>) }
    //     //     // if (sub) { return (<p key={i} className={`no${sub.id}`}>{`[ ${sub.text} ${sub.playState} ]`}</p>) }
    //     // })

    //     if (subs.length > 6) {
    //         subs = subs.slice(0, 6)
    //     }
    //     setsubtitles(subs)

    // }, [ subtitleQue ]);

    function removeSubtitle(id) {

        const newQue = subtitleQue.filter(sub => {
            if (sub && sub.id !== id) {
                return sub
            }
        })
        setSubtitleQue(newQue)
        // console.log(subtitleQue.length, subtitleQue)
    }

    function readLetterPositions(index, pos) {
        letterPositions.push(pos);
        setLetterPositions(letterPositions);
    }

    function saveLetterPositions(arr, id) {
        console.log(id)
        const url = `${process.env.REACT_APP_API_URL}/compositions`
        const data = {
            'id': null,
            'shareId': id,
            'positions': arr
        }
        fetch(
            url,
            {
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
                method: "POST"
            }
        )
            .then(data => data.json())
            .then((json) => {
                console.log(JSON.stringify(json))
            })
    }

    function loadSubtitle(sub) {
        // const subIndex = subtitleQue.length > 0 ? 1 : 0;
        // const newObj = {...sub,index:subIndex}
        // const newArr = subIndex ? [...subtitleQue,newObj] : [newObj,...subtitleQue]


        setSubtitleQue([ sub, ...subtitleQue ])
        // console.log(subtitleQue, sub)
    }

    // function updateSubtitle(id, state) {
    //     console.log(`updatedSub:${id, state}`)
    //     const updatedArr = subtitleQue.map(sub => {
    //         if (sub && sub.id === id) {
    //             console.log(`updatedSub:${sub.playState} state:${state}`)
    //             return { ...sub, playState: state }
    //         } else {
    //             return sub
    //         }
    //     })
    //     console.log(updatedArr)
    //     setSubtitleQue(updatedArr)
    // }

    return (
        <StateContext.Provider
            value={
                {
                    // subtitles: subtitles,
                    subtitleQue: subtitleQue,
                    loadSubtitle: loadSubtitle,
                    removeSubtitle: removeSubtitle,
                    // updateSubtitle: updateSubtitle,
                    letterPositions: letterPositions,
                    setPositions: readLetterPositions,
                    recPos: recPos,
                    setRec: setRecPos,
                    setShareId: setShareId,
                    hasChanged: hasChanged,
                    setHasChanged: setHasChanged,
                    preComp: preComp,
                    setPreComp: setPreComp,
                    globalZ: globalZ,
                    setGlobalZ: setGlobalZ
                }
            }
        >
            {props.children}
        </StateContext.Provider>

    );
}

export default StateContextProvider