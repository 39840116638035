import React, { useEffect, useState } from 'react';
// import seed from 'random-seed';


function Image(props) {

    const [ imgFile, setUrl ] = useState(null);
    // const [ fader, setFader ] = useState(props.fader);
    const [ fullClip, setFullClip ] = useState([ 50, 50 ]); //[ 50-0  , 50 - 100]
    const [ foldClipArray, setFoldClipArray ] = useState([ 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50 ])
    const [ foldClipStr, setFoldClipStr ] = useState("");
    const [ direction, setDirection ] = useState(1);
    // const [randSeed, setRandSeed] = useState(seed.create("yo"));



    useEffect(() => {
        // const url = "http://triple.jsmdev.xyz:1337/uploads/IMG_5379_212d808a1f.JPG"
        const url = `${process.env.REACT_APP_API_URL}/${props.url}`
        // console.log(props.url)
        fetch(url)
            .then(resp => { return resp.blob(); })
            .then(blob => {
                // console.log(blob)
                setUrl(URL.createObjectURL(blob));
                // setRandSeed(url);

            });       // console.log("FETCH!")
        // setRandSeed(seed.create("yo"));
        setRandomClip();

        // handleFader()




    }, [])

    //IF DIRECTION IS DOWN

    useEffect(() => {
        if (props.down === direction) {
            handleFader()
        } else {
            setFullClip([ 50, 50 ])
        }

        // console.log(fader, props.hasStartPos)
    }, [ props, direction ])


    useEffect(() => {
        setDirection(props.direction);
    }, [ props.direction ]);

    // useEffect(() => {
    //     // console.log(props.snap);
    //     if (props.down === direction) { setRandomClip(); handleFader(); }
    // }, [ props.snap ])

    function handleFader() {
        // setFader(props.fader)

        // console.log(props.fader)
        //         const multiplier = 1;
        // //                    start                 multiplier
        // //                      |                        |
        //         const clip1 = (67*(1-(props.fader*multiplier)));

        //         const clip2 = (70*(props.fader*multiplier))+30;
        setFullClip([ clipMapper(50, 0, 2), clipMapper(50, 100, 2) ])

        const dest0 = [ 0, 1, 3, 5, 12, 14 ];
        const dest50 = [ 2, 7, 10, 15 ];
        const dest100 = [ 4, 6, 8, 9, 11, 13 ];
        let newClipArr = [];

        foldClipArray.forEach((int, index) => {
            if (dest0.includes(index)) {
                newClipArr.push(clipMapper(int, 0, 1));
            }
            if (dest50.includes(index)) {
                newClipArr.push(clipMapper(int, 50, 1));
                // newClipArr.push(int);
            }
            if (dest100.includes(index)) {
                newClipArr.push(clipMapper(int, 100, 1));
            }
        })
        // setFoldClipArray(newClipArr);
        clipArrayToStr(newClipArr);

        // console.log(foldClipArray);
        function clipMapper(start, dest, multiplier) {
            let result;
            result = (dest - start) * ((-props.fader * direction) * multiplier) + start;
            return result;
        }
    }

    function setRandomClip() {
        // const rand1 = seed.create(props.seed);
        let newArr = foldClipArray.map(() => Math.round(Math.random() * 100));
        // let newArr = foldClipArray.map(() => Math.round(rand1(101)));
        setFoldClipArray(newArr);
        clipArrayToStr(newArr);
    }

    function clipArrayToStr(arr) {

        let newClipStr = "";
        arr.forEach((int, i) => {
            const addToStr = i % 2 === 0 ? `${int}% ` : `${int}%,`;
            newClipStr += addToStr;
        })
        const parsedString = newClipStr.slice(0, -1);
        setFoldClipStr(parsedString);
        // console.log(parsedString)

    }


    // polygon (left 0% - 100% , top 0% 
    //                               - 

    //                              100%)

    return (
        <div
            className="fold"
            data-debug={props.fader}
            style={
                {
                    clipPath: `polygon(0% 0%, 50% ${fullClip[ 0 ]}%, 100% 0%, ${fullClip[ 1 ]}% 50%, 100% 100%, 50% ${fullClip[ 1 ]}%, 0% 100%, ${fullClip[ 0 ]}% 50%)`,
                    // display:`block`,
                    // display:`${props.down === direction && props.fader !== 0 ? "block" : "none"}`,
                    ...props.style
                }
            }

        >
            <img src={imgFile} style={{
                clipPath: `polygon(${foldClipStr})`,
                width: '100%',
            }}
                alt="yoyo"
                // onLoad={console.log(`${props.url} has loaded`)}
            >

            </img >

        </div>
    )
}

export default Image;
