import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactMarkdown from "react-markdown";

const TextContainer = styled.div`
    position:fixed;
    width:calc(100vw - 10rem);
    left:0;
    transform: ${props => `translateY(${props.px}px)`};
    /* top:${props => `calc(100vh + ${props.px}px)`}; */
    top:100vh;
    font-size:4.4rem ;
    text-align: justify;
    text-justify: inter-word;
    opacity:1;
    color:blue;
    white-space:pre-line;
    margin:0 5rem;
    cursor:default ;
    p {text-transform:initial;}
    h1 {
        font-size:7rem ;
        
    }
    /* height:100vh ; */
`

function Text(props) {

    const ref = useRef()
    const [textHeight, settextHeight] = useState(0);
    const [faderValue, setFaderValue] = useState(0)

    useEffect(() => {
        if (props.down === props.direction) {
            setFaderValue(props.fader)
        } else {
            setFaderValue(0)
        }
    }, [ props ])

    useEffect(() => {
        
        const box = ref.current.getBoundingClientRect()
        // console.log(box.height)
        settextHeight(box.height)
        
    }, []);



    return ( 
        <TextContainer ref={ref} px={textHeight*Math.abs(faderValue)*-1}>
            <ReactMarkdown>
                {props.text}
            </ReactMarkdown>
                <span style={{display:"block",marginBottom:"50vh"}}></span>
        </TextContainer>
     );
}

export default Text;