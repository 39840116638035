import React, { useState, useEffect, useContext } from 'react';
import Ticker from 'react-ticker';
import { StateContext } from "./stateContext";
import ReactMarkdown from "react-markdown";

function TextDescription(props) {
    const {text, id} = props

    const {subtitleQue, globalZ} = useContext(StateContext)
    // console.log(sub.text,sub.id,que[index].text)

    const [loaded, setloaded] = useState(false);
    const [index, setindex] = useState();
    const [zIndex, setzIndex] = useState();
    const [speed, setspeed] = useState();
    // const [length, setlength] = useState();
    // const [text, setText] = useState()

    // const zIndex = globalZ
    const zLevel = 10;

    useEffect(() => {
        // setText(text)
        setindex(subtitleQue.length)
        setzIndex(globalZ + zLevel)
        setTimeout(() => {
            setloaded(true)
            
        }, 10);
        setspeed(4 + (Math.floor(Math.random() * 6)))
        // setTimeout(() => {
        //     return () => {
        //         setloaded(0)
        //     }
            
        // }, 100);

    }, []);

    useEffect(() => {
        const que = [...subtitleQue]
        que.reverse()
        // find new index postion in array (reversed for continuity)
        const index = que.findIndex(s => s.id === id)

        // update postion index
        if(index >= 0) {
            setindex(index)
        }

    }, [subtitleQue]);

    useEffect(() => {
        setzIndex(globalZ + zLevel)
    }, [globalZ]);

    return ( 
        <div className='tickerContainer' style={{
                            bottom:`${index * 5}rem`,
                            height:`${loaded ? "4rem" : "0rem"}`,
                            zIndex:zIndex,
                            fontWeight:400,
                            pointerEvents:"none",
                            userSelect:"none",
                            }}>
            <Ticker mode="smooth" speed={speed}>
                {({index}) => (
                    <>
                        <div className='tickerContent'><span style={{marginLeft:"10rem"}}></span>{text}</div>
                    </>
                )}
                

            </Ticker>
        </div>
     );
}

export default TextDescription;