import React, { useEffect, useState, useRef, useContext } from 'react';
import { StateContext } from "./stateContext";
import TextDescription from './TextDescription';

function AudioTrack(props) {


    const { loadSubtitle, removeSubtitle } = useContext(StateContext)

    // const [ volume, setVolume ] = useState(0);
    const audio = useRef(null);
    const [ direction, setDirection ] = useState(null);
    const [ playState, setPlayState ] = useState("stopped")
    // const [ showSub, setShowSub ] = useState(false)
    const [ subObj, setsubObj ] = useState({ id: props.id, text: props.description, playState: "stopped" });

    const {fader, down, mediaRef} = props
    // console.log("render")
    // useEffect(() => {
    //     console.log("useEffect")
    //     // setsubObj({ id: props.id, text: props.description, playState: "stopped" })
    // }, []);

    //START AUDIO AND SHOW SUBTITLE, AND UPDATE PLAYSTATE

    // MAKE A VOLUME CLIPPER IN BACKEND!!

    useEffect(() => {
        // console.log(props.fader)
        if (audio && direction === down) {
            audio.current.volume = Math.abs(fader) <= 1 ? Math.abs(fader) : 1;
            if (Math.abs(fader) > 0.035) { //&& audio.current.paused
                audio.current.play();
                // console.log("playing", props.char)
                // if (!showSub) { setShowSub(true) }
                // setPlayState("playing");
                // if (subObj.playState !== "useEffect") { setShowSub(true) }
                // console.log("show sub")
            }
            if (Math.abs(fader) === 0 && !audio.current.paused || Math.abs(fader) <= 0.035) {
                audio.current.pause()
                // setPlayState("stopped");
            }
            // if (Math.abs(props.fader) <= 0.035) {
            //     setPlayState("stopped");
            // }
        }
        if (audio && direction !== down) {
            audio.current.pause()
            // setPlayState("stopped")
        }
    }, [ fader, direction ]);

    useEffect(() => {
        mediaRef(audio.current)
    }, []);

    //UPDATE SUBTITLES TO REFLECT PLAYSTATE
    useEffect(() => {
        if (subObj && playState === "playing") {
            setsubObj({ ...subObj, playState: playState })
            // updateSubtitle(subObj.id, playState)
            loadSubtitle(subObj)
        }
        // if (props.moving) { setShowSub(true) }
        if(subObj && playState === "stopped") {
            removeSubtitle(subObj.id)
            setsubObj({ ...subObj, playState: playState })
        }
        // console.log(`show sub ${playState} ${subObj.id}`)
    }, [ playState ]);

    useEffect(() => {
        setDirection(props.direction)
    }, [ props.direction ]);




    return (
        <React.Fragment>
            {/* <p style={{ fontSize: "1rem", position: 'fixes', bottom: '0', left: "50%" }}>{playState}</p> */}
            <div className="contentContainer">
                <audio
                    style={{ display: "none", position: "fixed" }}
                    loop
                    onPlaying={() => { setPlayState("playing") }}
                    onPause={() => { setPlayState("stopped") }}
                    ref={audio}
                    controls src={`${process.env.REACT_APP_API_URL}/${props.track}`}
                    type="audio/mpeg"
                    // volume={volume}
                    // data-isplaying={props.hasStartPos}
                >
                </audio>
                {/* {showSub ? <p className="textDescription">{`[ ${props.description} ${playState}]`}</p> : null} */}

            </div>
            {subObj && playState === "playing" ? 
            <TextDescription text={subObj.text} id={subObj.id} />
            : null
            }

        </React.Fragment>

    )
}

export default AudioTrack;